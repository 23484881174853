<template>
  <main class="poster-page page">
    <BreadcrumbsComponent
      v-if="poster && poster.title"
      :title="poster.title"
      :links="[{ title: 'Афиша', to: { name: 'posters' } }]"
    />
    <div class="container-padding">
      <div v-if="poster" class="poster-page__inner">
        <ImgComponent v-if="poster.img" :img="poster.img" class="poster-page__image" />
        <div class="poster-page__body">
          <h2 v-if="poster.title" class="poster-page__title">{{ poster.title }}</h2>
          <span v-if="poster.description">{{ poster.description }}</span>
        </div>
        <div class="poster-page__footer">
          <span v-if="poster.limit">
            <b>Возрастное ограничение: </b>
            <span>{{ poster.limit }} +</span>
          </span>
          <span v-if="poster.date">
            <b>Дата проведения: </b>
            <span>{{ poster.date | humanDateTime }}</span>
          </span>
          <span v-if="poster.place">
            <b>Место проведения: </b>
            <span>{{ poster.place }}</span>
          </span>
          <span v-if="poster.phone_help">
            <b>Справки по телефону: </b>
            <span>{{ poster.phone_help }}</span>
          </span>
        </div>
        <div class="poster-page__buy">
          <a href="#afisha/014311f843f004f2d9d82b76b93b245ddced0cda24f729" class="btn btn--md btn--main">
            Купить билет
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import POSTERS_OPEN_PAGE from "gql/pages/PostersOpenPage.graphql";

export default {
  name: "PosterPage",
  metaInfo() {
    const meta = this.$store.state.posters.posters_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: POSTERS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("posters/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    poster() {
      return this.$store.state.posters.posters_item;
    },
  },
  components: {
    BreadcrumbsComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.poster-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 30px
  }

  &__image {
    max-height 550px
    border-radius 10px
  }

  &__body {
    display grid
    grid-gap 15px
  }

  &__title {
    margin-bottom 0
  }

  &__footer {
    display flex
    flex-direction column
    align-items flex-start
    gap 5px
  }

  &__buy {
    width 100%
    display flex
    justify-content flex-end

    a {
      +below(700px) {
        width 100%
      }
    }
  }
}
</style>
